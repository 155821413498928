import React, { useRef, useState } from "react";
import { ReactComponent as Logo } from "./Assets/logo.svg";
import { ReactComponent as FormLogo } from "./Assets/Forms-amico.svg";
import PopUp from "./modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

function BasicExample() {
    return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
}

// export default BasicExample;

function Enrollment() {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState(null);
    const [filename, setFilename] = useState({
        passport_image: "",
        id_image: "",
    });
    const image = useRef("");
    const image2 = useRef("");
    const [data, setData] = useState({
        surname: "",
        firstname: "",
        middlename: "",
        gender: "",
        dob: "",
        phone: "",
        email: "",
        plan: "",
        plan_type: "",
        enrollment_date: "",
        status: "",
        relationship: "",
        primary_hospital: "",
        secondary_hospital: "",
        pre_existing_condition: "",
        address: "",
        passport_image: "",
        id_image: "",
    });

    console.log(data);

    function encodeImageFileAsURL(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        setFilename({ ...filename, [e.target.name]: file.name });
        reader.onloadend = function () {
            setData({ ...data, [e.target.name]: reader.result });
        };
        reader.readAsDataURL(file);
    }

    const getimage = (props) => {
        if (props === "passport") {
            image2.current.click();
        } else {
            image.current.click();
        }
    };

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const create = (data) => {
        setIsLoading(true);
        axios
            .post(`https://api.paddycover.com/v1/axa/enrollment`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                console.log(res);
                setIsLoading(false);
                setShow(true);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message);
            });
    };

    const form = document.getElementById("form-group");

    const clearform = () => {
        setShow(false);
        form.reset();
    };

    const click = (e) => {
        e.preventDefault();
        create(data);
    };
    return (
        <div className="form-container">
            <div className="d-flex justify-content-between">
                <Logo />
                {/* <button className="button">Contact Us</button> */}
            </div>
            <div className=" justify-content-between split">
                <div className="enroll-image">
                    <FormLogo />
                </div>
                <div className="enroll-form">
                    <h3 className="text-center enrol-header">ENROLLMENT</h3>
                    <form id="form-group" className="form-group" onSubmit={click}>
                        <div className="input-group">
                            <div>
                                <label>Surname</label>
                                <input
                                    type="text"
                                    name="surname"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>First Name</label>
                                <input
                                    type="text"
                                    name="firstname"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Middle Name</label>
                                <input
                                    type="text"
                                    name="middlename"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Gender</label>
                                <select name="gender" onChange={onChange} required>
                                    <option>Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Date Of Birth</label>
                                <input type="date" name="dob" onChange={onChange} required />
                            </div>
                            <div>
                                <label>Phone Number</label>
                                <input type="tel" name="phone" onChange={onChange} required />
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Email</label>
                                <input type="email" name="email" onChange={onChange} required />
                            </div>
                            <div>
                                <label>Plan</label>
                                {/* <input type="text" /> */}
                                <select name="plan" onChange={onChange} required>
                                    <option>Select plan</option>
                                    <option>Paddy Care</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Plan Type</label>
                                <select name="plan_type" onChange={onChange} required>
                                    <option>Select Plan</option>
                                    <option>Individual</option>
                                    <option>Family</option>
                                </select>
                            </div>
                            <div>
                                <label>Enrollment Date</label>
                                <input
                                    type="date"
                                    name="enrollment_date"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            {/* <div>
                                <label>Status (member/dependent)</label>
                                <select name="status" onChange={onChange} required>
                                    <option>Select Plan</option>
                                    <option>Dependent</option>
                                </select>
                            </div> */}
                            <div>
                                <label>Relationship (husband/wife/son/daughter)</label>
                                <input
                                    type="text"
                                    name="relationship"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>
                                    Upload Valid ID{" "}
                                    <span className="example">
                                        (Driver’s licence,Voter’s Card )
                                    </span>
                                </label>
                                <input
                                    type="file"
                                    ref={image}
                                    name="id_image"
                                    onChange={encodeImageFileAsURL}
                                    required
                                    hidden
                                />
                                <div className="d-flex">
                                    <span
                                        className="upload_button"
                                        onClick={() => getimage("id")}
                                    >
                                        Upload Here
                                    </span>
                                    <span className="filename">{filename.id_image}</span>
                                </div>
                                {/* <span className="upload_button" onClick={() => getimage("id")}>Upload Here</span><span>{filename.id_image}</span> */}
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Primary Hospital</label>
                                <input
                                    type="text"
                                    name="primary_hospital"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Upload Passport</label>
                                <input
                                    type="file"
                                    ref={image2}
                                    name="passport_image"
                                    onChange={encodeImageFileAsURL}
                                    required
                                    hidden
                                />
                                <div className="d-flex">
                                <span
                                    className="upload_button"
                                    onClick={() => getimage("passport")}
                                >
                                    Upload Here
                                </span>
                                <span className="filename">{filename.passport_image}</span>
                                </div>
                                
                            </div>
                        </div>
                        <div className="input-group">
                            <div>
                                <label>Pre-Existing Condition</label>
                                <textarea
                                    rows={2}
                                    name="pre_existing_condition"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea
                                    rows={2}
                                    name="address"
                                    onChange={onChange}
                                    required
                                />
                            </div>
                        </div>
                        <p>{error}</p>
                        <div className="text-center">
                            <button className="create-button">
                                {isLoading ? <BasicExample /> : "Create"}
                            </button>
                        </div>
                        <PopUp show={show} onHide={clearform} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Enrollment;
